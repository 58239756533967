






































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
import { I18nService } from '@cds/i18n';

@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {}
  private tableData: any[] = [];
  public loading: Boolean = true;
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    this.form = {
      size: 10,
      current: 1,
      total: 0,
      showType: '2',
      status: '2',
    };
    await this.getList();
  }
  // 搜索列表
  public search() {
    this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.awardsList(this.form || {});
    this.loading = false;
    this.tableData = res.records || [];
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0,
      showType: '2',
      status: '2',
    };
    this.search();
  }
  //新增
  public async handAdd(){
    localStorage.setItem('awardManagement', '-1')
    this.$router.push('/addAward');
  }
  //修改
  public async handUpdate(row: any){
    localStorage.setItem('awardManagement', row.name);
    this.$router.push('/addAward');
  }

}
